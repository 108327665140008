
.unread-bubble0-n {
  background-color: red;
  color: white;
  padding: 0px 5px;
  border-radius: 50%;
  position: absolute;
  right: -8px;
  margin-top: 3px;
}
.Review {
  .top_content {
    display: flex;
    position: relative;
    .left {
      width: 240px;
      color: #999;
      .v-select__slot {
        position: relative;
        left: 12px;
      }
      .v-input {
        position: relative;
        left: -20px;
        top: 16px;
        .v-input__slot {
          box-shadow: none;
          .v-text-field__slot {
            position: relative;
            left: 15px;
            color: #999;
          }
        }
        .v-input__prepend-outer {
          position: relative;
          left: 45px;
          top: 2px;
          z-index: 1;
          .v-icon.v-icon {
            color: #3f81c1 !important;
          }
        }
      }
    }
  }
  .tab_content {
    margin-left: 16px;
    .pagination {
      position: absolute;
      right: 10px;
      bottom: 20px;
    }
    .tab {
      width: 300px;
    }
    .v-card__text,
    .v-card__title {
      padding: 8px 8px 0 8px;
    }
    .v-card__actions {
      padding: 0 8px;
    }
    .menu_list:hover {
      .v-list-item__title {
        color: #3f81c1;
      }
    }
    .tabs_items {
      width: calc(100% - 16px);
      .tab_list {
        position: relative;
        margin-bottom: 70px;
      }

      .card_item {
        background: #fff;
        position: relative;
        width: 100%;
        height: 132px;
        padding: 10px 0;
        border-bottom: 1px solid #f0f0f0;
        display: flex;
        .card_item_img {
          width: 215px;
          height: 112px;
          position: relative;
          border-radius: 6px;
        }
        .card_item_content {
          margin-left: 8px;
          width: calc(100% - 580px);
          height: 112px;
          align-items: center;
          .content_title {
            font-weight: bold;
            font-size: 14px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .content_text {
            font-size: 12px;
            margin-top: 4px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          .content_info {
            position: absolute;
            bottom: 0;
            display: flex;
            font-size: 12px;
            height: 40px;
            line-height: 40px;
            .info_name {
              font-size: 14px;
              margin-left: 4px;
            }
            .info_time {
              font-size: 12px;
              margin-left: 24px;
              line-height: 44px;
            }
          }
        }
        .content_btn {
          width: 40px;
          margin: 0 100px 0 0;
          height: 25px;
          line-height: 25px;
          text-align: center;
          align-self: center;
          font-weight: bold;
          font-size: 14px;
          color: #c4c4c4;
          cursor: pointer;
        }
        .content_btn2 {
          margin: 0 0 0 100px;
          position: relative;
          left: 0px;
        }
        .content_btn3 {
          width: 40px;
          margin: 0 100px;
          height: 25px;
          line-height: 25px;
          text-align: center;
          align-self: center;
          font-weight: bold;
          font-size: 14px;
          color: #c4c4c4;
          cursor: pointer;
        }
        .content_status {
          position: absolute;
          right: 0;
          top: 10px;
          width: 80px;
          height: 40px;
          line-height: 40px;
          border-bottom-left-radius: 25px;
          background: #ffaf46;
          text-align: center;
          color: #fff;
          font-size: 14px;
        }
      }
      .text_title {
        line-height: 20px;
        font-weight: bold;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .text_content {
        font-size: 12px;
        color: #666;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        line-height: 20px;
      }
      .tabs_items_img {
        width: 136px;
        height: 106px;
        margin: 0 auto;
        .menu_list:hover {
          .v-list-item__title {
            color: #3f81c1;
          }
        }
      }
    }
  }
}
